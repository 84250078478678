* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  scrollbar-width: thin;
  scrollbar-color: #333;
}

:root {
  --primary_color: #006aff;
}

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 0;
  }
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 50px;
}

body {
  background-color: rgba(0, 0, 0, 0.15);
  height: auto;
  overflow-x: hidden;
  overflow-y: hidden;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.root {
  border-radius: 10px;
  min-width: 400px;
}

.MTableToolbar-highlight-24 {
  background-color: transparent !important;
  color: var(--primary_color) !important;
}

.import-btn {
  margin-left: 0px;
}

.organizations-input {
  margin-top: 20px !important;
}

.organizations-wrapper-select {
  margin-top: 20px;
}

.empty-table-wrapper {
  height: 90vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .root {
    min-width: 320px;
  }
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.font-style-1 {
  font-weight: 700 !important;
  font-size: 0.95rem !important;
  // font-family: 'Poppins', sans-serif !important;
}

.font-style-2 {
  font-weight: 500 !important;
  font-size: 0.85rem !important;
}

.font-style-3 {
  font-weight: 500 !important;
  font-size: 0.95rem !important;
}

.font-table-row {
  padding: 5px 7px !important;
}

.font-table-row span {
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.created-offers-buttons {
  display: flex;
  justify-content: space-between;
}

.tmb-second-card {
  margin-top: 30px;
}

.text-align-right {
  text-align: right !important;
}

.justify-between {
  display: flex !important;
  justify-content: space-between !important;
}

.align-center {
  display: flex !important;
  align-items: center !important;
}

// .align-center-second {
//     display: flex !important;
//     justify-content: center !important;
// }
.text-align-right.space-between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.sort-products {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  // border: 1px sol/id black;
  padding: 10px 0;
}

.card-size-wrapper {
  width: 100%;
  padding: 10px;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  transition-duration: 0.28s;
}

.card-size-wrapper-second {
  width: 100%;
  padding: 10px;
  height: auto;
  overflow-y: scroll;
  transition-duration: 0.28s;
}

.offers-between,
.pro-order-title {
  display: flex;
  justify-content: space-between;
}

.card-size-inner {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 110px);
  padding: 10px;
  color: #000;
}

.txt_dec_none {
  text-decoration: none !important;
}

.p-0 {
  padding: 0 !important;
}

.p-10 {
  padding: 10px;
}

.m-10 {
  margin: 10px;
}

.m-30 {
  margin-top: 30px;
}

.m-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px !important;
}

.h-40 {
  height: 40vh !important;
}

.w-100 {
  width: 100%;
}

.mt-1 {
  margin-top: 1rem;
}

.w-50 {
  width: 50%;
}

.w-46 {
  width: 46%;
}

.d-flex {
  display: flex;
}

.position-relative {
  position: relative;
}

.cross-sell-products-discount {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  // border: 1px solid black;
}

.cross-sell-products-discount .textfield-products {
  width: 48.5% !important;
}

.cross-sell-products-discount .textfield-products .child {
  width: 100%;
}

.MuiTouchRipple-root {
  background-color: transparent !important;
}

.MuiPickersDay-daySelected:focus {
  color: white !important;
  background-color: #3f51b5 !important;
}

.staff-list-number {
  margin-bottom: 0 !important;
}

.processing-added {
  margin-top: 30px;
  text-align: right;
}

.process-added-info {
  text-align: center;
  margin-bottom: 20px;
}

.processing-added-btn {
  margin: 0 20px !important;
}

.input-mask {
  width: 100% !important;
  text-indent: 10px;
}

.input-mask label {
  margin-top: -7px !important;
}

.input-mask-supplier {
  width: 100%;
}

.staff-list-number {
  text-indent: 0 !important;
}

.staff-list-number label {
  width: 100% !important;
  margin-top: 0 !important;
}

.light-offers {
  font-weight: lighter !important;
}

.page {
  position: relative;
}

.input-holder-label-upload {
  position: absolute;
  top: 20% !important;
  background-color: white;
  opacity: 0;
  height: 200px;
  width: 200px;
  cursor: pointer;
  // left: 2% !important;
  // transform: translateY(-50%) !important;
}

.agreement-wrapper {
  display: flex !important;
  flex-direction: column !important;

  justify-content: center !important;
  padding-left: 20px;
}

.agreement-wrapper-info,
.agreement-calc p {
  text-align: justify;
  width: 95%;
  font-size: 16px;
  line-height: 1.5;
}

.agreement-number,
.agreement-wrap-title h4,
.agreement-calc h4 {
  text-align: center;
  font-size: 20px;
}

.agreement-mentor-info-left p,
.agreement-mentor-info-right p {
  color: #272f55;
}

.agreement-mentor-info-left p:last-child,
.agreement-mentor-info-right p:last-child {
  margin-top: 40px !important;
}

.download-agreement {
  background-color: #d3d8d8 !important;
  text-transform: capitalize !important;
  font-weight: bold !important;
  margin: 30px 10px !important;
}

.post-btn {
  text-transform: capitalize !important;
}

.agreement-calc h4 {
  margin-top: 30px;
}

.table-wrapper .agreement-table {
  width: 95%;
  border: 1px solid #030303;
  border-collapse: collapse;
}

.agreement-table .th,
.td {
  border: 1px solid #030303;
  text-align: center;
}

.agreement-pdf {
  width: 100% !important;
}

.agreement-card {
  display: flex !important;
  justify-content: center !important;
  width: 45% !important;
  margin-bottom: 20px !important;
}

.agreement-btn {
  width: 90%;
  text-align: right;
}

.th,
.td {
  padding: 6px;
}

.table-wrapper-info h5,
.table-wrapper-info p {
  text-align: center;
}

.table-wrapper-info p:last-child {
  text-align: left;
}

.agreement-mentor-info {
  margin-top: 20px;
  width: 89%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1300px) {
  .table-wrapper .agreement-table {
    width: 100% !important;
  }
  .agreement-card {
    min-width: 600px !important;
  }
  .agreement-wrapper {
    width: 100% !important;
  }
  .agreement-wrapper-info,
  .agreement-calc p {
    width: 100% !important;
  }
}

.form-group-wrap {
  padding: 54px 64px;
}

@media only screen and (max-width: 768px) {
  .MuiButton-label {
    font-size: 12px;
  }
  .input-mask label {
    font-size: 12px;
  }
  .login-btn {
    font-size: 10px !important;
  }
  .form-group-wrap {
    padding: 54px 15px;
  }
}

.MuiButton-containedPrimary {
  background-color: #006aff !important;
}

.MuiTableCell-body {
  font-weight: 400 !important;
}

.MuiTableCell-head {
  font-weight: bold !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #006aff !important;
}

.MuiFormLabel-root h3 {
  font-size: 16px !important;
}

.MuiPaper-root.MuiTableContainer-root.MuiPaper-elevation1.MuiPaper-rounded {
  box-shadow: none;
}

.MuiTableContainer-root {
  overflow-x: hidden !important;
}

.table-data-time {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.makeStyles-container-11 {
  width: inherit !important;
}

.after {
  margin-top: 35px;
}

.table-header {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.drag-and-drop-title {
  margin: 20px 0px 30px 45px;
}

.header1x-btn {
  text-transform: capitalize !important;
}

// import excel in products
.import-card-first {
  display: flex !important;
  // align-self: flex-start !important;
  justify-content: flex-start !important;
}

.import-items {
  margin-bottom: 20px;
}

.import-items h2 {
  color: #000000de !important;
  font-weight: lighter !important;
}

.import-items p > a {
  color: var(--primary_color);
}

.import-items-wrapper {
  width: 95% !important;
}

.import-items-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  // border: 3px dashed grey;
  background-color: #f2f2f2;
}

.import-items-border .import-items-icon i {
  font-size: 70px;
  color: rgba(0, 0, 0, 0.54);
}

.import-items-info {
  position: relative;
  margin-left: 15px;
}

.import-items-info h3 {
  color: #000000de;
  font-weight: lighter !important;
  margin-bottom: 10px;
}

.upload-items-btn-group {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.upload-items-btn-group .upload-first-btn .btn,
.upload-second-btn .btn {
  font-weight: bold !important;
}

@media only screen and (max-width: 576px) {
  .import-card-first {
    width: 450px !important;
  }
  .import-items-border {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .import-items-info {
    text-align: center !important;
  }
  .import-items-info h3 {
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
  }
  .drop-file {
    position: relative;
  }
  .drop-file input {
    position: absolute;
    left: 0%;
  }
}

.MuiOutlinedInput-input {
  padding: 10px !important;

  &::after {
    content: "";
    position: absolute;
    right: 44px;
    width: 1.5px;
    height: 25px;
  }
}

.MuiFormLabel-filled {
  background-color: transparent !important;
}

.css-2b097c-container {
  width: 75px;
  margin-right: 10px;
  color: #333 !important;
}

.accordion-input-name {
  flex: 1;
}

.MuiButton-textPrimary {
  color: #18a0fb !important;
}

.MuiAccordionSummary-root.Mui-focused {
  background-color: transparent !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.nav_link {
  text-decoration: none;
  color: #000;
}

.nav_link_active {
  z-index: -100 !important;
}

.nav_link_active div {
  pointer-events: none !important;
  cursor: none;
}

.label-data {
  position: relative;
  top: -15px;
  margin: 0;
  padding: 0;
}

.accordion-table {
  width: 700px !important;
}

.finance-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}

.finance-history {
  position: absolute;
  right: 30px;
  top: 80px;
}

.add-count {
  top: 20px;
  position: absolute !important;
  right: 10px !important;
}

.finance-history button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: rgb(235, 235, 235);
  border-radius: 30px;
  color: black;
  width: 120px;
  height: 35px;
  cursor: pointer;
}

.finance-history img {
  margin-right: 10px;
}

// .MuiButtonBase-root:focus {
//      background-color: transparent;
// }
.MuiButton-contained:hover {
  background-color: transparent !important;
}

@media only screen and (max-width: 1500px) {
  .accordion-card {
    width: 60% !important;
  }
  .accordion-input {
    width: 100% !important;
  }
  .accordion-input-second {
    width: 145px;
  }
  .accordion-table {
    min-width: 700px !important;
  }
}

@media only screen and (max-width: 992px) {
  .accordion-card {
    width: 70% !important;
  }
  .accordion-input-second {
    width: 150px;
  }
}

@media only screen and (max-width: 868px) {
  .accordion-input-second {
    width: 120px;
  }
  .accordion-input label {
    font-size: 13px;
  }
  .accordion-input-second label {
    font-size: 13px;
  }
}

@media only screen and (max-width: 768px) {
  .accordion-div {
    display: flex !important;
    flex-direction: column !important;
  }
  .accordion-table-container {
    width: 100% !important;
    min-width: 600px !important;
    overflow-x: scroll !important;
  }
  .accordion-table {
    overflow-x: scroll !important;
    min-width: 600px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 400px) {
  .accordion-input,
  .accordion-input-second {
    width: 170px;
    margin-left: 0px !important;
  }
}

@mixin d-fl {
  display: flex;
  align-items: center;
}

@mixin d-ss {
  @include d-fl;
  justify-content: center;
}

@mixin d-sb {
  @include d-fl;
  justify-content: space-between;
}

@mixin d-sa {
  @include d-fl;
  justify-content: space-around;
}

@mixin reducerClass {
  .div-one,
  .div-two,
  .div-three,
  .external-div {
    @include d-sb;
    width: 100%;
    padding: 10px;

    .wrap-item {
      flex: 1;
    }

    .wrap-item-one {
      padding-right: 10px;
    }

    .wrap-item-two {
      padding-left: 10px;
    }

    .wrap-item.write-item-one.list-item-wrapper {
      @include d-sb;
      width: 100%;

      input[type="checkbox"] {
        transform: scale(1.5);
      }

      input[type="text"] {
        width: 100px;
      }
    }

    #demo-simple-selection,
    #dataTime-up {
      transform: translateY(-7px);
    }

    @media only screen and (max-width: 500px) {
      & {
        flex-direction: column !important;

        .wrap-item {
          width: 100% !important;

          &-one,
          &-two {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

.disp-fl,
.inner-span,
.in-d-sb,
.tree-child-two,
.payment-data-wrapper {
  @include d-fl;
}

.staff-list-org,
.class-d-sb {
  @include d-sb;
}

.reducerClass {
  @include reducerClass;
}

.navbar-icon {
  width: 30px;
  height: auto;
}

.disp-sb {
  display: flex;
  align-items: flex-start;

  input {
    position: relative;
    top: 5px;
  }
}

.unClick {
  color: #ccc;
  pointer-events: none !important;
}

// .MuiListItem-root {
//     padding-top: 20px !important;
// }
.MuiAppBar-colorPrimary {
  background-color: #006aff !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #006aff !important;
}

.MuiButtonBase-root .MuiButton-root .MuiButton-contained {
  background: #ffffff !important;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
}

.MuiSelect-select:focus {
  background-color: #fff !important;
}

.MuiDropzoneArea-root {
  width: 40% !important;
  margin: 50px 0 !important;
}

.MuiTableCell-root {
  font-size: 16px !important;
}

.MuiTypography-displayBlock h5 {
  font-size: 16px;
}

.buttons-group {
  padding-top: 40px;
  padding-bottom: 30px;
  position: relative;
}

.info-document-title {
  line-height: 2;
  font-size: 16px;
}

.data-picker {
  width: 300px !important;
}

.data-picker-form-control {
  width: 100%;
}

.process-btn {
  margin: 30px !important;
}

.checkbox-group {
  padding: 10px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &-input {
    position: absolute;
    left: 19.5%;
  }

  &-child {
    margin: 0 65px 0 -10px !important;
  }
}

//////using
.button-submit {
  width: 100%;
}

.button-submit .button-one {
  margin: 0 10px;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.img-holder {
  position: relative;
  width: 150px;
  height: 150px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 1rem;

  .img {
    width: 150px;
    height: 150px;
    cursor: pointer;
    object-fit: cover;
    margin-left: -3px !important;
    margin-top: -3px;
  }

  &-input {
    display: none;
  }
}

.img-holder-taskbar {
  position: relative;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // border: 3px solid black;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: 1rem;

  .taskbar-upload-image {
    width: 40px;
    // height: 100px;
    cursor: pointer;
    object-fit: cover;
    margin-left: -3px !important;
    margin-top: -3px;
  }

  &-input {
    display: none;
  }
}

.inner-span {
  // @include d-fl;
  align-items: flex-end !important;

  p {
    margin: 0;
    padding: 0;
    transform: translate(-5px, 0px);
  }
}

.position-check {
  @include d-sb;
  margin-top: 10px;
  border-bottom: 1px solid #949494;
}

@media only screen and (max-width: 450px) {
  .logo-png {
    display: none;
  }
  .nav-wrapper {
    @include d-sb;
  }
}

.header {
  @include d-sb;

  &-one,
  &-two {
    @include d-sa;

    .btn-header {
      width: 180px;
      font-size: 0.9rem;
      text-transform: capitalize;
    }
  }

  &-one {
    width: 100%;
    justify-content: space-between !important;
  }

  &-two.header2x {
    justify-content: flex-end;
  }

  &-one.header5x {
    align-self: flex-start;
    flex: 1 !important;
  }

  &-one.header4x,
  &-two.header4x {
    .dropdown {
      min-width: 130px;
    }
  }

  &-two.header5x {
    flex: 3 !important;
    justify-content: space-between;
    justify-content: flex-end;
  }

  &-two.header5x {
    flex-wrap: wrap;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
  }

  @media only screen and (max-width: 662px) {
    &-one.header5x,
    &-two.header5x {
      justify-content: center;
    }
    &.header2x {
      flex-direction: column;
    }
    &-one.header2x,
    &-two.header2x {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  @media only screen and (max-width: 975px) {
    &-one,
    &-two {
      width: 100% !important;
    }
  }
  @media only screen and (max-width: 440px) {
    &-one,
    &-two {
      flex-direction: column;

      span {
        padding: 0;
      }

      .btn-header {
        width: 275px !important;
      }
    }
    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
      width: 250px !important;
    }
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
      margin-right: 11px !important;
    }

    &-one.header4x,
    &-two.header4x {
      span {
        margin: 0 auto;
      }

      .dropdown {
        width: 290px;
        margin-left: 10px;
      }
    }
  }
  @media only screen and (max-width: 510px) {
    &-two.header4x {
      flex-direction: column;
      align-items: flex-end !important;
    }
  }
  @media only screen and (max-width: 660px) {
    & {
      flex-direction: column;
    }
    &-one {
      width: 100%;
    }
  }
  @media only screen and (max-width: 710px) {
    &.header4x {
      flex-direction: column;
    }
    &-one.header4x,
    &-two.header4x {
      width: 100%;
      justify-content: space-between;
    }
    .client-create {
      width: 80% !important;
    }
  }
}

//////USING...
.create {
  &-card {
    // background-color:lightgreen;
    &-div {
      @include d-fl; /// changed from sb to fl
      flex-direction: column !important;
      min-width: 350px;
      max-width: 1000px;
      // width: auto!important;
      // background-color:lightblue;
    }

    &-first {
      width: 100%;
      @include d-fl;
      @include reducerClass;
      align-self: flex-start;
      flex-direction: column;
      padding: 10px;

      .external-wrapper {
        .external-div {
          height: 50px;
        }
      }

      .discount {
        width: 100%;
        margin: 10px 0;

        &-radio {
          @include d-fl;
          flex-direction: row;
        }

        .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
          margin-top: 13px;
        }
      }

      .list-group-wrapper {
        width: 100%;
        padding: 10px;
        margin-top: 50px;

        ul {
          li {
            div {
              @include d-sb;
              padding: 0 10px;
              font-size: 0.8rem;
            }
          }
        }
      }
    }

    .sale-offers-between {
      display: flex !important;
      justify-content: space-between;
    }

    &-second {
      // max-width:1000px;
      // min-width: 750px;
      width: 100%;
      margin-top: 20px;
      padding: 10px;
      align-self: flex-start;

      h3.products {
        margin: 10px;
      }
    }

    .discount {
      width: 100%;
      margin: 10px 0;

      &-radio {
        @include d-fl;
        flex-direction: row;
      }

      .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
        margin-top: 13px;
      }
    }
  }

  .text-counter {
    position: relative;

    span {
      position: absolute;
      right: 0;
      bottom: -20px;
      font-size: 0.83rem;
    }
  }

  @media only screen and (max-width: 1250px) {
    &-card {
      // flex-direction: column !important;
      justify-content: flex-start;

      &-first,
      &-second {
        // width:700px;
        overflow-x: hidden;
      }

      &-second {
        margin-top: 20px;
      }
    }
  }
  @media only screen and (max-width: 540px) {
    &-card {
      &-first,
      &-second {
        width: 100% !important;
      }
    }
  }
}

///USING....
.client-create {
  align-self: flex-start;
  width: 50%;
  @include reducerClass;

  .div-one {
    margin: 10px 0;
  }

  &-buttons {
    margin: 10px 0;
    text-align: right;
  }

  @media only screen and (max-width: 640px) {
    & {
      width: 100% !important;
    }
  }
}

.client-profile {
  hr {
    margin: 10px;
  }

  &-buttons {
    @include d-sb;
    margin: 10px 0;
  }

  &-main {
    margin: 40px 0;
    text-align: center;
  }

  &-info {
    @include reducerClass;

    .block .wrap-item {
      @include d-fl;

      img {
        transform: translate(-5px, -5px);
      }

      p {
        margin: 4px 0;
      }
    }

    .icon-flex {
      display: flex;
      flex-direction: column;

      img {
        width: 38px;
      }
    }
  }
}

.create-stocks {
  &-card-first {
    padding: 30px 0;
  }
}

// !!!! SALE - SAVDO/TIJORAT !!!!

.list-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &-item {
    line-height: 3;
    display: block;
    width: 100%;
  }

  &-line {
    width: 500px !important;
  }
}

.timehd {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;

  &-position {
    flex-wrap: wrap;
    margin-right: 10px;
  }

  @media only screen and (max-width: 440px) {
    & {
      flex-direction: column;
    }
  }
}

.x-data {
  width: 300px;
  margin-left: auto;
  margin-right: 10px;

  @media only screen and (max-width: 662px) {
    transform: translateX(10px);
    margin-right: auto;
  }
  @media only screen and (max-width: 440px) {
    width: 277px;
    transform: translateX(4px);
  }
}

.special-div {
  @include d-sb;

  .special-class {
    &-one,
    &-two {
      @include d-fl;
    }

    &-one {
      flex: 3;
    }

    &-two {
      flex: 3;
      padding-left: 50px;
      justify-content: space-between !important;
    }

    .MuiFormControl-root.MuiTextField-root.data-picker-form-control.MuiFormControl-marginNormal {
      margin-left: 10px;
    }
  }

  @media only screen and (max-width: 915px) {
    & {
      flex-direction: column !important;

      .special-class-two {
        width: 100%;
        padding-left: 10px;
        justify-content: space-around !important;
      }
    }
  }
  @media only screen and (max-width: 484px) {
    .special-class {
      flex-direction: column !important;

      #formcontrol {
        width: 100% !important;
      }
    }
  }
}

.tabs-wrapper {
  .tabs-container {
    @include d-sb;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.payment-wrapper {
  .payment-button-wrapper {
    @include d-sb;
    margin-bottom: 10px;
  }

  .payment-data-wrapper {
    // @include d-fl;
    border-bottom: 1px solid #ccc;

    h4 {
      margin-left: 10px;
      font-weight: 400;
    }
  }

  .payment-data-wrapper.extra {
    @include d-sb;
  }
}

.payment-popover {
  @include d-sb;
}

.payment-create-wrapper {
  width: 100%;

  div {
    width: 100%;
    text-align: center;
  }

  div:nth-child(1) {
    padding: 10px 0;
  }

  img {
    width: 100px;
    margin-top: 20px;
  }
}

.payment-loyalty {
  .text-field {
    margin-top: 10px;
  }

  .btn-payment-loyalty {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .text-field label {
    font-size: 10px !important;
    line-height: 1.3;
    margin-top: -10px !important;
  }
  .product-card-first {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }
}

.btn-card-avoid {
  margin-top: 15px;
  overflow: hidden;
}

.btn-card-avoid.d-flex-second {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.sms-wrapper {
  @include d-sb;
  width: 100%;
  height: 100vh;

  .sms-card {
    width: 400px;
    height: auto;
    margin: 0 auto;
    padding: 30px;
    text-align: center;
  }

  .sms-text {
    margin: 10px 0;
  }

  .sms-button {
    margin-top: 20px;
  }
}

.pagination-part {
  @include d-sb;
  margin-top: 30px;
}

.list-item-h4 {
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.flex-container {
  @include d-sb;

  span {
    font-size: 0.95rem;
  }
}

.row-style-56 {
  font-weight: 600;
  font-size: 0.9rem;
}

//----------------------------- BUGS FIX ------------------
.copy-offers-total {
  display: flex;
  justify-content: space-between;
  margin: 100px 0 30px 0;
  border-top: 1px solid rgb(190, 189, 189);
  padding: 10px;
}

.copy-offers-total-count {
  display: flex;
}

.copy-offers-total h4:first-child {
  margin-right: 30px;
}

.delete-vs-create-btn {
  margin-top: 20px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

@media only screen and (max-width: 476px) {
  .delete-vs-create-btn .button-one {
    margin: 15px 2px;
  }
}

.what-organisation-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 80%;
}

.div-one-flex {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  margin: 20px 0;
}

.div-one-flex
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-adornedEnd {
  width: 100% !important;
}

.layout-card-schedule {
  overflow-y: auto !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  min-height: auto !important;
  margin-top: 10px !important;
  margin-bottom: 100px !important;
  transition-duration: 0.28s !important;
}

.section-organisation-list {
  display: flex !important;
  justify-content: space-between !important;
}

.table-paging {
  // border: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.material-table-input {
  width: 30%;
}

.post-org-item-wrap {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.post-org-item-wrap-second {
  display: flex;
  justify-content: space-between;
}

.post-org-item-wrap-second li {
  line-height: 50px;
}

.post-org-item-wrap-second input[type="checkbox"] {
  margin: 0 10px;
}

.post-org-item-wrap-second input {
  border: none;
  border-bottom: 1px solid grey;
  outline: none;
}

.checkbox-left {
  margin: 0px 10px;
  width: 17px;
  height: 17px;
}

.in-d-sb {
  //  @include d-fl;
  input {
    position: relative;
    left: 12px;
  }
}

.staff-list-org {
  //  @include d-sb;
  //  margin:0 10px;
  span {
    flex: 4;
    width: 100%;
    margin: 0 10px;
  }
}

.tree-staff-list {
  .tree-child {
    &-one {
      height: auto;
      margin: 10px 0;

      .list-btn {
        flex: 1;
        position: relative;
        top: 10px;

        &-btn {
          position: relative;
          top: 5px;
          margin: 0;
          outline: none;
          border: none;
          color: #006aff;
          cursor: pointer;
          background-color: transparent;
        }

        &-btn:active,
        &-btn:focus,
        &-btn:hover {
          background-color: transparent !important;
        }

        &-btn:active {
          transform: scale(1.5);
          transition: all ease 0.5s;
        }
      }
    }

    &-two {
      // @include d-fl;
      div {
        margin: 10px;

        input {
          margin-right: 5px;
        }
      }
    }
  }
}

.organization-checkbox {
  width: 17px;
  height: 17px;
}

.pin-code {
  margin-right: auto !important;
  margin-bottom: 10px;
}

.styles_react-code-input__CRulA {
  input {
    font-size: 0.9rem;
    width: 50px !important;
    margin: 0 4px !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #888 !important;
  }
}

.spinner-center {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
}

.create-position-input {
  width: 40% !important;
}

.popup-menu {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}

.WithStyles\(ForwardRef\(MenuItem\)\)-root-24:focus {
  background-color: var(--primary_color) !important;
}

.WithStyles\(ForwardRef\(MenuItem\)\)-root-60:focus {
  background-color: var(--primary_color) !important;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.WithStyles\(ForwardRef\(MenuItem\)\)-root-62.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  background-color: var(--primary_color) !important;
}

.products-create-organizations {
  width: 70% !important;
}

.autocomp-input {
  width: 200px !important;
}

.font-style-cell {
  text-align: left !important;
}

.products-create-organizations {
  width: 55px !important;
}

.x4_times {
  display: flex;
  width: 600px !important;
  margin-left: auto;

  div.w-100 {
    margin-right: 20px;
  }
}

.d-flex-return {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.modal-transfer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-paper {
  background-color: rgba(0, 0, 0, 0.15);
}

.modal-btn-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

#transition-modal-title {
  font-weight: 400;
}

.taskbar-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.taskbar-inside-projects-btn {
  font-size: 17px !important;
  display: flex !important;
  align-items: center !important;
}

.org-button.text-align-right {
  margin-top: 10px;
}

.MuiFormControl-root.MuiTextField-root.w-100 {
  position: relative;
}

.MuiFormHelperText-root.Mui-error {
  position: absolute;
  bottom: -18px;
  font-family: "Nunito", sans-serif;
  // font-weight: bold;
}

.switch-discount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.supplier-fields {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.supplier-fields .wrap-item {
  flex: 1 !important;
}

.supplier-fields .wrap-item-one,
.wrap-item-two {
  padding-right: 10px;
}

// FINANCE CASH STYLES

.finance-cash-btn-child {
  width: 90%;
  height: 50px;
}

.MuiTreeItem-label {
  background-color: transparent !important;
}

.empty-text {
  position: relative;
  left: 50%;
  transform: translateX(50%);
  width: 100%;
  font-weight: lighter !important;
  // border: 1px solid black;
  text-align: center !important;
}

.MTableToolbar-highlight-8 {
  background-color: transparent !important;
  color: white !important;
}

.MuiDialog-paperWidthSm {
  width: 65% !important;
}

.MTableToolbar-highlight-61,
.MTableToolbar-highlight-10 {
  background-color: transparent !important;
  color: white !important;
}

.dialog-input {
  display: flex !important;
  align-items: center !important;
  border: 1px solid #eaeaea !important;
  padding: 8px 7px !important;
  border-left: 0 !important;
  border-right: 0 !important;
  max-height: 50px !important;
}

.mt-1 {
  margin-top: 1rem;
}

.dialog-input-second {
  display: flex !important;
  align-items: center !important;
  border: 1px solid #eaeaea !important;
  padding: 8px 7px !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.dialog-textField input[type="text"] {
  text-indent: 30px !important;
}

.scrollbar-hide {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.create-barcode-wrapper {
  width: 100%;
  height: 100vh;
  background-color: white;
  overflow-y: scroll !important;
}

.create-barcode-wrapper-top {
  display: flex !important;
  align-items: center !important;
  padding-left: 78px;
  height: 50px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24) !important;
}

.create-barcode-print-btn {
  padding: 30px 30px 0 78px;
}

.print-btn {
  width: 90px !important;
  height: 37px !important;
  border-radius: 2px !important;
  font-size: 16px !important;
}

.category-table-child {
  padding: 4px !important;
}

.create_barcode_card {
  width: 210mm;
  display: flex;
  margin: 0 78px;
  justify-content: flex-start !important;
  flex-wrap: wrap !important;
  padding: 30px 0;
  overflow-x: hidden !important;
  border-collapse: collapse;
}

.format_zebra {
  width: 60mm;
  display: flex;
  margin: 0 78px;
  // justify-content: center !important;
  flex-wrap: wrap !important;
}

.create_barcode_child_one {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  width: 70mm;
  height: 37mm;
}

.create_barcode_child_second {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  width: 70mm;
  height: 29.7mm;
}

.create_barcode_child_third {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  width: 52.5mm;
  height: 29.7mm;
}

.create_barcode_child_four {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  width: 40mm;
  height: 30mm;
}

.create_barcode_child_five {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  width: 58mm;
  height: 40mm;
}

.create_barcode_child_six {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  width: 30mm;
  height: 21mm;
}

.create_barcode_card tr {
  border: 1px solid rgb(105, 105, 105);
}

.create_barcode_child_one span,
.create_barcode_child_second span,
.create_barcode_child_third span {
  padding: 5px 0 0 0;
  font-size: 12px;
}

.create_barcode_child_four span,
.create_barcode_child_five span,
.create_barcode_child_six span {
  font-size: 13px;
  text-align: center !important;
}

.create-barcode td {
  text-align: center !important;
}

.empty-data {
  text-align: center !important;
  width: 100% !important;
}

.barcode-table-child {
  display: flex;
  justify-content: center;
  width: 96.5%;
}

@media only screen and (max-width: 576px) {
  .zoom-to-response {
    zoom: 1;
  }
}

@media (min-width: 768px) {
  .responsive-select .MuiSelect-select:after {
    content: none !important;
  }
  .responsive-select .MuiInputBase-root {
    font-size: 7px !important;
  }
  .responsive-select .MuiSelect-select {
    padding: 10px 16px 4px 7px !important;
    font-size: 12px !important;
  }
  .zoom-to-response {
    zoom: 1;
  }
}

@media (min-width: 992px) {
  .responsive-select label {
    font-size: 12px !important;
  }
  .responsive-select .MuiSelect-select:after {
    content: none !important;
  }
  .responsive-select .MuiInputBase-root {
    font-size: 7px !important;
  }
  .responsive-select .MuiSelect-select {
    padding: 10px 16px 4px 7px !important;
    font-size: 12px !important;
  }
  .zoom-to-response {
    zoom: 1;
  }
}

@media (min-width: 1200px) {
  .responsive-select label {
    font-size: 12px !important;
  }
  .responsive-select .MuiSelect-select:after {
    content: none !important;
  }
  .responsive-select .MuiInputBase-root {
    font-size: 7px !important;
  }
  .responsive-select .MuiSelect-select {
    padding: 10px 16px 4px 7px !important;
    font-size: 12px !important;
  }
  .zoom-to-response {
    zoom: 1;
  }
}

@media (min-width: 1400px) {
  .responsive-select label {
    font-size: unset !important;
  }
  .responsive-select .MuiSelect-select:after {
    content: "" !important;
  }
  .responsive-select .MuiInputBase-root {
    font-size: unset !important;
  }
  .responsive-select .MuiSelect-select {
    padding: 10px !important;
    font-size: unset !important;
  }
  .zoom-to-response {
    zoom: 1;
  }
}

@media only screen and (max-width: 440px) {
  .header
    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    width: 200px !important;
  }
  .header-one .btn-header,
  .header-two .btn-header {
    width: 200px !important;
  }
}

// from docs
