* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  scrollbar-width: thin;
  scrollbar-color: #333;
  /* outline: 1px solid red !important; */
}

div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper,
div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAutocomplete-paper {
  border-radius: 10px;
}

body {
  margin: 0px;
}
::selection {
  color: #fff;
  background: #006aff;
}
:root {
  --primary_color: #006aff;
}
/**/
@media print {
  @page {
    margin: 0;
  }

  body {
    margin: 0;
  }
}

/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 7px;
  height: 7px !important;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #006aff;
  border-radius: 50px;
}

body {
  background-color: rgba(0, 0, 0, 0.15);
  height: auto;
  overflow-x: hidden;
  overflow-y: hidden;
}

body .fr-popup.fr-desktop.fr-active {
  z-index: 10000 !important;
}
/* 
input:-webkit-autofill {
  -webkit-background-clip: text;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.root {
  border-radius: 10px;
  min-width: 400px;
}

.MTableToolbar-highlight-24 {
  background-color: transparent !important;
  color: var(--primary_color) !important;
}

.import-btn {
  margin-left: 0px;
}

.organizations-input {
  margin-top: 20px !important;
}

.organizations-wrapper-select {
  margin-top: 20px;
}

.empty-table-wrapper {
  height: 90vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .root {
    min-width: 320px;
  }
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.font-style-1 {
  font-weight: 700 !important;
  font-size: 0.95rem !important;
}

.font-style-2 {
  font-weight: 500 !important;
  font-size: 0.85rem !important;
}

.font-style-3 {
  font-weight: 500 !important;
  font-size: 0.95rem !important;
}

.font-table-row {
  padding: 5px 7px !important;
}

.font-table-row span {
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}

.box-shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.created-offers-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.tmb-second-card {
  margin-top: 30px;
}

.text-align-right {
  text-align: right !important;
}

.justify-between {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.align-center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.text-align-right.space-between {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.sort-products {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 10px 0;
}

.card-size-wrapper {
  width: 100%;
  padding: 10px;
  height: calc(100vh - 64px);
  overflow-y: auto;
  -webkit-transition-duration: 0.28s;
  transition-duration: 0.28s;
}

.card-size-wrapper-second {
  width: 100%;
  padding: 10px;
  height: auto;
  overflow-y: scroll;
  -webkit-transition-duration: 0.28s;
  transition-duration: 0.28s;
}

.offers-between,
.pro-order-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.card-size-inner {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 110px);
  padding: 10px;
  color: #000;
}

.txt_dec_none {
  text-decoration: none !important;
}

.p-0 {
  padding: 0 !important;
}

.p-10 {
  padding: 10px;
}

.m-10 {
  margin: 10px;
}

.m-30 {
  margin-top: 30px;
}

.m-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px !important;
}

.h-40 {
  height: 40vh !important;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-46 {
  width: 46%;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cross-sell-products-discount {
  width: 100%;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.cross-sell-products-discount .textfield-products {
  width: 48.5% !important;
}

.cross-sell-products-discount .textfield-products .child {
  width: 100%;
}

.MuiTouchRipple-root {
  background-color: transparent !important;
}

.MuiPickersDay-daySelected:focus {
  color: white !important;
  background-color: #3f51b5 !important;
}

.staff-list-number {
  margin-bottom: 0 !important;
}

.processing-added {
  margin-top: 30px;
  text-align: right;
}

.process-added-info {
  text-align: center;
  margin-bottom: 20px;
}

.processing-added-btn {
  margin: 0 20px !important;
}

.input-mask {
  width: 100% !important;
  text-indent: 10px;
}

.input-mask label {
  margin-top: -7px !important;
}

.input-mask-supplier {
  width: 100%;
}

.staff-list-number {
  text-indent: 0 !important;
}

.staff-list-number label {
  width: 100% !important;
  margin-top: 0 !important;
}

.light-offers {
  font-weight: lighter !important;
}

.page {
  position: relative;
}

.input-holder-label-upload {
  position: absolute;
  top: 20% !important;
  background-color: white;
  opacity: 0;
  height: 150px;
  width: 150px;
  cursor: pointer;
}

.agreement-wrapper {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  padding-left: 20px;
}

.agreement-wrapper-info,
.agreement-calc p {
  text-align: justify;
  width: 95%;
  font-size: 16px;
  line-height: 1.5;
}

.agreement-number,
.agreement-wrap-title h4,
.agreement-calc h4 {
  text-align: center;
  font-size: 20px;
}

.agreement-mentor-info-left p,
.agreement-mentor-info-right p {
  color: #272f55;
}

.agreement-mentor-info-left p:last-child,
.agreement-mentor-info-right p:last-child {
  margin-top: 40px !important;
}

.download-agreement {
  background-color: #d3d8d8 !important;
  text-transform: capitalize !important;
  font-weight: bold !important;
  margin: 30px 10px !important;
}

.post-btn {
  text-transform: capitalize !important;
}

.agreement-calc h4 {
  margin-top: 30px;
}

.table-wrapper .agreement-table {
  width: 95%;
  border: 1px solid #030303;
  border-collapse: collapse;
}

.agreement-table .th,
.td {
  border: 1px solid #030303;
  text-align: center;
}

.agreement-pdf {
  width: 100% !important;
}

.agreement-card {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  width: 45% !important;
  margin-bottom: 20px !important;
}

.agreement-btn {
  width: 90%;
  text-align: right;
}

.th,
.td {
  padding: 6px;
}

.table-wrapper-info h5,
.table-wrapper-info p {
  text-align: center;
}

.table-wrapper-info p:last-child {
  text-align: left;
}

.agreement-mentor-info {
  margin-top: 20px;
  width: 89%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 1300px) {
  .table-wrapper .agreement-table {
    width: 100% !important;
  }

  .agreement-card {
    min-width: 600px !important;
  }

  .agreement-wrapper {
    width: 100% !important;
  }

  .agreement-wrapper-info,
  .agreement-calc p {
    width: 100% !important;
  }
}

.form-group-wrap {
  padding: 54px 64px;
}

@media only screen and (max-width: 768px) {
  .MuiButton-label {
    font-size: 12px;
  }

  .input-mask label {
    font-size: 12px;
  }

  .login-btn {
    font-size: 10px !important;
  }

  .form-group-wrap {
    padding: 54px 15px;
  }
}

.MuiTableCell-body {
  font-weight: 400 !important;
}

.MuiTableCell-head {
  font-weight: bold !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #006aff !important;
}

.MuiFormLabel-root h3 {
  font-size: 16px !important;
}

.MuiPaper-root.MuiTableContainer-root.MuiPaper-elevation1.MuiPaper-rounded {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.table-data-time {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.makeStyles-container-11 {
  width: inherit !important;
}

.after {
  margin-top: 35px;
}

.table-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  text-align: center;
}

.drag-and-drop-title {
  margin: 20px 0px 30px 45px;
}

.header1x-btn {
  text-transform: capitalize !important;
}

.import-card-first {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.import-items {
  margin-bottom: 20px;
}

.import-items h2 {
  color: #000000de !important;
  font-weight: lighter !important;
}

.import-items p > a {
  color: var(--primary_color);
}

.import-items-wrapper {
  width: 95% !important;
}

.import-items-border {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 180px;
  background-color: #f2f2f2;
}

.import-items-border .import-items-icon i {
  font-size: 70px;
  color: rgba(0, 0, 0, 0.54);
}

.import-items-info {
  position: relative;
  margin-left: 15px;
}

.import-items-info h3 {
  color: #000000de;
  font-weight: lighter !important;
  margin-bottom: 10px;
}

.upload-items-btn-group {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.upload-items-btn-group .upload-first-btn .btn,
.upload-second-btn .btn {
  font-weight: bold !important;
}

@media only screen and (max-width: 576px) {
  .import-card-first {
    width: 450px !important;
  }

  .import-items-border {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .import-items-info {
    text-align: center !important;
  }

  .import-items-info h3 {
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
  }

  .drop-file {
    position: relative;
  }

  .drop-file input {
    position: absolute;
    left: 0%;
  }
}

.MuiOutlinedInput-input {
  padding: 10px !important;
}

.MuiOutlinedInput-input::after {
  content: "";
  position: absolute;
  top: 7px;
  right: 44px;
  width: 1.5px;
  height: 25px;
}

.MuiFormLabel-filled {
  background-color: transparent !important;
}

.css-2b097c-container {
  width: 75px;
  margin-right: 10px;
  color: #333 !important;
}

.accordion-input-name {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.MuiButton-textPrimary {
  color: #18a0fb !important;
}

.MuiAccordionSummary-root.Mui-focused {
  background-color: transparent !important;
}

.MuiPaper-elevation1 {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.nav_link {
  text-decoration: none;
  color: #000;
}

.nav_link_active {
  z-index: -100 !important;
  color: #026aff !important;
}

.nav_link_active div {
  pointer-events: none !important;
  cursor: none;
}

.label-data {
  position: relative;
  top: -15px;
  margin: 0;
  padding: 0;
}

.accordion-table {
  width: 700px !important;
}

.finance-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.finance-history {
  position: absolute;
  right: 30px;
  top: 80px;
}

.add-count {
  top: 20px;
  position: absolute !important;
  right: 10px !important;
}

.finance-history button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: #ebebeb;
  border-radius: 30px;
  color: black;
  width: 120px;
  height: 35px;
  cursor: pointer;
}

.finance-history img {
  margin-right: 10px;
}

.MuiButton-contained:hover {
  background-color: none !important;
}

@media only screen and (max-width: 1500px) {
  .accordion-card {
    width: 60% !important;
  }

  .accordion-input {
    width: 100% !important;
  }

  .accordion-input-second {
    width: 145px;
  }

  .accordion-table {
    min-width: 700px !important;
  }
}

@media only screen and (max-width: 992px) {
  .accordion-card {
    width: 70% !important;
  }

  .accordion-input-second {
    width: 150px;
  }
}

@media only screen and (max-width: 868px) {
  .accordion-input-second {
    width: 120px;
  }

  .accordion-input label {
    font-size: 13px;
  }

  .accordion-input-second label {
    font-size: 13px;
  }
}

@media only screen and (max-width: 768px) {
  .accordion-div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .accordion-table-container {
    width: 100% !important;
    min-width: 600px !important;
    overflow-x: scroll !important;
  }

  .accordion-table {
    overflow-x: scroll !important;
    min-width: 600px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 400px) {
  .accordion-input,
  .accordion-input-second {
    width: 170px;
    margin-left: 0px !important;
  }
}

.disp-fl,
.inner-span,
.in-d-sb,
.tree-child-two,
.payment-data-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.staff-list-org,
.class-d-sb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.reducerClass .div-one,
.reducerClass .div-two,
.reducerClass .div-three,
.reducerClass .external-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.reducerClass .div-one .wrap-item,
.reducerClass .div-two .wrap-item,
.reducerClass .div-three .wrap-item,
.reducerClass .external-div .wrap-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.reducerClass .div-one .wrap-item-one,
.reducerClass .div-two .wrap-item-one,
.reducerClass .div-three .wrap-item-one,
.reducerClass .external-div .wrap-item-one {
  padding-right: 10px;
}

.reducerClass .div-one .wrap-item-two,
.reducerClass .div-two .wrap-item-two,
.reducerClass .div-three .wrap-item-two,
.reducerClass .external-div .wrap-item-two {
  padding-left: 10px;
}

.reducerClass .div-one .wrap-item.write-item-one.list-item-wrapper,
.reducerClass .div-two .wrap-item.write-item-one.list-item-wrapper,
.reducerClass .div-three .wrap-item.write-item-one.list-item-wrapper,
.reducerClass .external-div .wrap-item.write-item-one.list-item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.reducerClass
  .div-one
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"],
.reducerClass
  .div-two
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"],
.reducerClass
  .div-three
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"],
.reducerClass
  .external-div
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"] {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.reducerClass
  .div-one
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"],
.reducerClass
  .div-two
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"],
.reducerClass
  .div-three
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"],
.reducerClass
  .external-div
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"] {
  width: 100px;
}

.reducerClass .div-one #demo-simple-selection,
.reducerClass .div-one #dataTime-up,
.reducerClass .div-two #demo-simple-selection,
.reducerClass .div-two #dataTime-up,
.reducerClass .div-three #demo-simple-selection,
.reducerClass .div-three #dataTime-up,
.reducerClass .external-div #demo-simple-selection,
.reducerClass .external-div #dataTime-up {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}

@media only screen and (max-width: 500px) {
  .reducerClass .div-one,
  .reducerClass .div-two,
  .reducerClass .div-three,
  .reducerClass .external-div {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .reducerClass .div-one .wrap-item,
  .reducerClass .div-two .wrap-item,
  .reducerClass .div-three .wrap-item,
  .reducerClass .external-div .wrap-item {
    width: 100% !important;
  }

  .reducerClass .div-one .wrap-item-one,
  .reducerClass .div-one .wrap-item-two,
  .reducerClass .div-two .wrap-item-one,
  .reducerClass .div-two .wrap-item-two,
  .reducerClass .div-three .wrap-item-one,
  .reducerClass .div-three .wrap-item-two,
  .reducerClass .external-div .wrap-item-one,
  .reducerClass .external-div .wrap-item-two {
    padding-left: 0;
    padding-right: 0;
  }
}

.navbar-icon {
  width: 30px;
  height: auto;
}

.disp-sb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.disp-sb input {
  position: relative;
  top: 5px;
}

.unClick {
  color: #ccc;
  pointer-events: none !important;
}

.MuiAppBar-colorPrimary {
  background-color: #006aff !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #006aff !important;
}

.MuiButtonBase-root .MuiButton-root .MuiButton-contained {
  background: #ffffff !important;
  -webkit-box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
}

.MuiSelect-select:focus {
  background-color: #fff !important;
}

.MuiDropzoneArea-root {
  width: 40% !important;
  margin: 50px 0 !important;
}

.MuiTableCell-root {
  font-size: 16px !important;
}

.MuiTypography-displayBlock h5 {
  font-size: 16px;
}

.buttons-group {
  padding-top: 40px;
  padding-bottom: 30px;
  position: relative;
}

.info-document-title {
  line-height: 2;
  font-size: 16px;
}

.data-picker {
  width: 300px !important;
}

.data-picker-form-control {
  width: 100%;
}

.process-btn {
  margin: 30px !important;
}

.checkbox-group {
  padding: 10px;
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox-group-input {
  position: absolute;
  left: 19.5%;
}

.checkbox-group-child {
  margin: 0 65px 0 -10px !important;
}

.button-submit {
  width: 100%;
}

.button-submit .button-one {
  margin: 0 10px;
}

.MuiPaper-elevation2 {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.img-holder {
  position: relative;
  display: flex;
  justify-content: space-between;
  /* height: 155px; */
  border-radius: 5px;
  margin-top: 1rem;
}
.img-holder .button-group {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  box-sizing: border-box;
  /* margin: 0 50px; */
}

@media (min-width: 870px) {
  .img-holder .button-group {
    margin-left: 20px;
  }
}

@media (min-width: 1050px) {
  .img-holder .button-group {
    margin: 0 50px;
  }
}

.img-holder .button-group .primary {
  width: 100%;
  cursor: pointer;
  padding: 6px 16px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 1.75;
  border-radius: 5px;
  text-transform: uppercase;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.img-holder .button-group .primary:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

.img-holder .img {
  width: 150px;
  height: 150px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-left: -3px !important;
  margin-top: -3px;
}

.img-holder-input {
  display: none;
}

.img-holder-taskbar {
  position: relative;
  width: 90px;
  height: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: 1rem;
}

.img-holder-taskbar .taskbar-upload-image {
  width: 40px;
  cursor: pointer;
  -o-object-fit: cover;
  object-fit: cover;
  margin-left: -3px !important;
  margin-top: -3px;
}

.img-holder-taskbar-input {
  display: none;
}

.inner-span {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.inner-span p {
  margin: 0;
  padding: 0;
  -webkit-transform: translate(-5px, 0px);
  transform: translate(-5px, 0px);
}

.position-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 10px;
  border-bottom: 1px solid #949494;
}

@media only screen and (max-width: 450px) {
  .logo-png {
    display: none;
  }

  .nav-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-one,
.header-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.header-one .btn-header,
.header-two .btn-header {
  width: 180px;
  font-size: 0.9rem;
  text-transform: capitalize;
}

.header-one {
  width: 100%;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.header-two.header2x {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header-one.header5x {
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 !important;
  flex: 1 !important;
}

.header-one.header4x .dropdown,
.header-two.header4x .dropdown {
  min-width: 130px;
}

.header-two.header5x {
  -webkit-box-flex: 3 !important;
  -ms-flex: 3 !important;
  flex: 3 !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header-two.header5x {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.header .MuiOutlinedInput-adornedEnd {
  padding-right: 0 !important;
}

@media only screen and (max-width: 662px) {
  .header-one.header5x,
  .header-two.header5x {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .header.header2x {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .header-one.header2x,
  .header-two.header2x {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 975px) {
  .header-one,
  .header-two {
    width: 100% !important;
  }
}

@media only screen and (max-width: 440px) {
  .header-one,
  .header-two {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .header-one span,
  .header-two span {
    padding: 0;
  }

  .header-one .btn-header,
  .header-two .btn-header {
    width: 275px !important;
  }

  .header
    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    width: 250px !important;
  }

  .header
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
    margin-right: 11px !important;
  }

  .header-one.header4x span,
  .header-two.header4x span {
    margin: 0 auto;
  }

  .header-one.header4x .dropdown,
  .header-two.header4x .dropdown {
    width: 290px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 510px) {
  .header-two.header4x {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
}

@media only screen and (max-width: 660px) {
  .header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .header-one {
    width: 100%;
  }
}

@media only screen and (max-width: 710px) {
  .header.header4x {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .header-one.header4x,
  .header-two.header4x {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .header .client-create {
    width: 80% !important;
  }
}

.create-card-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  min-width: 350px;
  max-width: 1060px;
}

.create-card-first {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px;
}

.create-card-first .div-one,
.create-card-first .div-two,
.create-card-first .div-three,
.create-card-first .external-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.create-card-first .div-one .wrap-item,
.create-card-first .div-two .wrap-item,
.create-card-first .div-three .wrap-item,
.create-card-first .external-div .wrap-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.create-card-first .div-one .wrap-item-one,
.create-card-first .div-two .wrap-item-one,
.create-card-first .div-three .wrap-item-one,
.create-card-first .external-div .wrap-item-one {
  padding-right: 10px;
}

.create-card-first .div-one .wrap-item-two,
.create-card-first .div-two .wrap-item-two,
.create-card-first .div-three .wrap-item-two,
.create-card-first .external-div .wrap-item-two {
  padding-left: 10px;
}

.create-card-first .div-one .wrap-item.write-item-one.list-item-wrapper,
.create-card-first .div-two .wrap-item.write-item-one.list-item-wrapper,
.create-card-first .div-three .wrap-item.write-item-one.list-item-wrapper,
.create-card-first .external-div .wrap-item.write-item-one.list-item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.create-card-first
  .div-one
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"],
.create-card-first
  .div-two
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"],
.create-card-first
  .div-three
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"],
.create-card-first
  .external-div
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"] {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.create-card-first
  .div-one
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"],
.create-card-first
  .div-two
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"],
.create-card-first
  .div-three
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"],
.create-card-first
  .external-div
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"] {
  width: 100px;
}

.create-card-first .div-one #demo-simple-selection,
.create-card-first .div-one #dataTime-up,
.create-card-first .div-two #demo-simple-selection,
.create-card-first .div-two #dataTime-up,
.create-card-first .div-three #demo-simple-selection,
.create-card-first .div-three #dataTime-up,
.create-card-first .external-div #demo-simple-selection,
.create-card-first .external-div #dataTime-up {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}

@media only screen and (max-width: 500px) {
  .create-card-first .div-one,
  .create-card-first .div-two,
  .create-card-first .div-three,
  .create-card-first .external-div {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .create-card-first .div-one .wrap-item,
  .create-card-first .div-two .wrap-item,
  .create-card-first .div-three .wrap-item,
  .create-card-first .external-div .wrap-item {
    width: 100% !important;
  }

  .create-card-first .div-one .wrap-item-one,
  .create-card-first .div-one .wrap-item-two,
  .create-card-first .div-two .wrap-item-one,
  .create-card-first .div-two .wrap-item-two,
  .create-card-first .div-three .wrap-item-one,
  .create-card-first .div-three .wrap-item-two,
  .create-card-first .external-div .wrap-item-one,
  .create-card-first .external-div .wrap-item-two {
    padding-left: 0;
    padding-right: 0;
  }
}

.create-card-first .external-wrapper .external-div {
  height: 50px;
}

.create-card-first .discount {
  width: 100%;
  margin: 10px 0;
}

.create-card-first .discount-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.create-card-first
  .discount
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  margin-top: 13px;
}

.create-card-first .list-group-wrapper {
  width: 100%;
  padding: 10px;
  margin-top: 50px;
}

.create-card-first .list-group-wrapper ul li div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 0.8rem;
}

.create-card .sale-offers-between {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.create-card-second {
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.create-card-second h3.products {
  margin: 10px;
}

.create-card .discount {
  width: 100%;
  margin: 10px 0;
}

.create-card .discount-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.create-card
  .discount
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  margin-top: 13px;
}

.create .text-counter {
  position: relative;
}

.create .text-counter span {
  position: absolute;
  right: 0;
  bottom: -20px;
  font-size: 0.83rem;
}

@media only screen and (max-width: 1250px) {
  .create-card {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .create-card-first,
  .create-card-second {
    overflow-x: hidden;
  }

  .create-card-second {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 540px) {
  .create-card-first,
  .create-card-second {
    width: 100% !important;
  }
}

.client-create {
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 50%;
}

.client-create .div-one,
.client-create .div-two,
.client-create .div-three,
.client-create .external-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.client-create .div-one .wrap-item,
.client-create .div-two .wrap-item,
.client-create .div-three .wrap-item,
.client-create .external-div .wrap-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.client-create .div-one .wrap-item-one,
.client-create .div-two .wrap-item-one,
.client-create .div-three .wrap-item-one,
.client-create .external-div .wrap-item-one {
  padding-right: 10px;
}

.client-create .div-one .wrap-item-two,
.client-create .div-two .wrap-item-two,
.client-create .div-three .wrap-item-two,
.client-create .external-div .wrap-item-two {
  padding-left: 10px;
}

.client-create .div-one .wrap-item.write-item-one.list-item-wrapper,
.client-create .div-two .wrap-item.write-item-one.list-item-wrapper,
.client-create .div-three .wrap-item.write-item-one.list-item-wrapper,
.client-create .external-div .wrap-item.write-item-one.list-item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.client-create
  .div-one
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"],
.client-create
  .div-two
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"],
.client-create
  .div-three
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"],
.client-create
  .external-div
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"] {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.client-create
  .div-one
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"],
.client-create
  .div-two
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"],
.client-create
  .div-three
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"],
.client-create
  .external-div
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"] {
  width: 100px;
}

.client-create .div-one #demo-simple-selection,
.client-create .div-one #dataTime-up,
.client-create .div-two #demo-simple-selection,
.client-create .div-two #dataTime-up,
.client-create .div-three #demo-simple-selection,
.client-create .div-three #dataTime-up,
.client-create .external-div #demo-simple-selection,
.client-create .external-div #dataTime-up {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}

@media only screen and (max-width: 500px) {
  .client-create .div-one,
  .client-create .div-two,
  .client-create .div-three,
  .client-create .external-div {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .client-create .div-one .wrap-item,
  .client-create .div-two .wrap-item,
  .client-create .div-three .wrap-item,
  .client-create .external-div .wrap-item {
    width: 100% !important;
  }

  .client-create .div-one .wrap-item-one,
  .client-create .div-one .wrap-item-two,
  .client-create .div-two .wrap-item-one,
  .client-create .div-two .wrap-item-two,
  .client-create .div-three .wrap-item-one,
  .client-create .div-three .wrap-item-two,
  .client-create .external-div .wrap-item-one,
  .client-create .external-div .wrap-item-two {
    padding-left: 0;
    padding-right: 0;
  }
}

.client-create .div-one {
  margin: 10px 0;
}

.client-create-buttons {
  margin: 10px 0;
  text-align: right;
}

@media only screen and (max-width: 640px) {
  .client-create {
    width: 100% !important;
  }
}

.client-profile hr {
  margin: 10px;
}

.client-profile-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 10px 0;
}

.client-profile-main {
  margin: 40px 0;
  text-align: center;
}

.client-profile-info .div-one,
.client-profile-info .div-two,
.client-profile-info .div-three,
.client-profile-info .external-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.client-profile-info .div-one .wrap-item,
.client-profile-info .div-two .wrap-item,
.client-profile-info .div-three .wrap-item,
.client-profile-info .external-div .wrap-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.client-profile-info .div-one .wrap-item-one,
.client-profile-info .div-two .wrap-item-one,
.client-profile-info .div-three .wrap-item-one,
.client-profile-info .external-div .wrap-item-one {
  padding-right: 10px;
}

.client-profile-info .div-one .wrap-item-two,
.client-profile-info .div-two .wrap-item-two,
.client-profile-info .div-three .wrap-item-two,
.client-profile-info .external-div .wrap-item-two {
  padding-left: 10px;
}

.client-profile-info .div-one .wrap-item.write-item-one.list-item-wrapper,
.client-profile-info .div-two .wrap-item.write-item-one.list-item-wrapper,
.client-profile-info .div-three .wrap-item.write-item-one.list-item-wrapper,
.client-profile-info .external-div .wrap-item.write-item-one.list-item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.client-profile-info
  .div-one
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"],
.client-profile-info
  .div-two
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"],
.client-profile-info
  .div-three
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"],
.client-profile-info
  .external-div
  .wrap-item.write-item-one.list-item-wrapper
  input[type="checkbox"] {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.client-profile-info
  .div-one
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"],
.client-profile-info
  .div-two
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"],
.client-profile-info
  .div-three
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"],
.client-profile-info
  .external-div
  .wrap-item.write-item-one.list-item-wrapper
  input[type="text"] {
  width: 100px;
}

.client-profile-info .div-one #demo-simple-selection,
.client-profile-info .div-one #dataTime-up,
.client-profile-info .div-two #demo-simple-selection,
.client-profile-info .div-two #dataTime-up,
.client-profile-info .div-three #demo-simple-selection,
.client-profile-info .div-three #dataTime-up,
.client-profile-info .external-div #demo-simple-selection,
.client-profile-info .external-div #dataTime-up {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}

@media only screen and (max-width: 500px) {
  .client-profile-info .div-one,
  .client-profile-info .div-two,
  .client-profile-info .div-three,
  .client-profile-info .external-div {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .client-profile-info .div-one .wrap-item,
  .client-profile-info .div-two .wrap-item,
  .client-profile-info .div-three .wrap-item,
  .client-profile-info .external-div .wrap-item {
    width: 100% !important;
  }

  .client-profile-info .div-one .wrap-item-one,
  .client-profile-info .div-one .wrap-item-two,
  .client-profile-info .div-two .wrap-item-one,
  .client-profile-info .div-two .wrap-item-two,
  .client-profile-info .div-three .wrap-item-one,
  .client-profile-info .div-three .wrap-item-two,
  .client-profile-info .external-div .wrap-item-one,
  .client-profile-info .external-div .wrap-item-two {
    padding-left: 0;
    padding-right: 0;
  }
}

.client-profile-info .block .wrap-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.client-profile-info .block .wrap-item img {
  -webkit-transform: translate(-5px, -5px);
  transform: translate(-5px, -5px);
}

.client-profile-info .block .wrap-item p {
  margin: 4px 0;
}

.client-profile-info .icon-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.client-profile-info .icon-flex img {
  width: 38px;
}

.create-stocks-card-first {
  padding: 30px 0;
}

.list-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.list-wrapper-item {
  line-height: 3;
  display: block;
  width: 100%;
}

.list-wrapper-line {
  width: 500px !important;
}

.timehd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0 10px;
}

.timehd-position {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 10px;
}

@media only screen and (max-width: 440px) {
  .timehd {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.x-data {
  width: 300px;
  margin-left: auto;
  margin-right: 10px;
}

@media only screen and (max-width: 662px) {
  .x-data {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
    margin-right: auto;
  }
}

@media only screen and (max-width: 440px) {
  .x-data {
    width: 277px;
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
}

.special-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.special-div .special-class-one,
.special-div .special-class-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.special-div .special-class-one {
  -webkit-box-flex: 3;
  -ms-flex: 3;
  flex: 3;
}

.special-div .special-class-two {
  -webkit-box-flex: 3;
  -ms-flex: 3;
  flex: 3;
  padding-left: 50px;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.special-div
  .special-class
  .MuiFormControl-root.MuiTextField-root.data-picker-form-control.MuiFormControl-marginNormal {
  margin-left: 10px;
}

@media only screen and (max-width: 915px) {
  .special-div {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .special-div .special-class-two {
    width: 100%;
    padding-left: 10px;
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
}

@media only screen and (max-width: 484px) {
  .special-div .special-class {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .special-div .special-class #formcontrol {
    width: 100% !important;
  }
}

.tabs-wrapper .tabs-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.tabs-wrapper p {
  margin: 0;
  padding: 0;
}

.payment-wrapper .payment-button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}

.payment-wrapper .payment-data-wrapper {
  border-bottom: 1px solid #ccc;
}

.payment-wrapper .payment-data-wrapper h4 {
  margin-left: 10px;
  font-weight: 400;
}

.payment-wrapper .payment-data-wrapper.extra {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.payment-popover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.payment-create-wrapper {
  width: 100%;
}

.payment-create-wrapper div {
  width: 100%;
  text-align: center;
}

.payment-create-wrapper div:nth-child(1) {
  padding: 10px 0;
}

.payment-create-wrapper img {
  width: 100px;
  margin-top: 20px;
}

.payment-loyalty .text-field {
  margin-top: 10px;
}

.payment-loyalty .btn-payment-loyalty {
  margin-top: 10px;
}

@media only screen and (max-width: 1200px) {
  .text-field label {
    font-size: 10px !important;
    line-height: 1.3;
    margin-top: -10px !important;
  }

  .product-card-first {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
}

.btn-card-avoid {
  margin-top: 15px;
  overflow: hidden;
}

.btn-card-avoid.d-flex-second {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.sms-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}

.sms-wrapper .sms-card {
  width: 400px;
  height: auto;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
}

.sms-wrapper .sms-text {
  margin: 10px 0;
}

.sms-wrapper .sms-button {
  margin-top: 20px;
}

.pagination-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
}

.list-item-h4 {
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-container span {
  font-size: 0.95rem;
}

.row-style-56 {
  font-weight: 600;
  font-size: 0.9rem;
}

.copy-offers-total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 100px 0 30px 0;
  border-top: 1px solid #bebdbd;
  padding: 10px;
}

.copy-offers-total-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.copy-offers-total h4:first-child {
  margin-right: 30px;
}

.delete-vs-create-btn {
  margin-top: 20px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

@media only screen and (max-width: 476px) {
  .delete-vs-create-btn .button-one {
    margin: 15px 2px;
  }
}

.what-organisation-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 80%;
}

.div-one-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  width: 100% !important;
  margin: 20px 0;
}

.div-one-flex
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-adornedEnd {
  width: 100% !important;
}

.layout-card-schedule {
  overflow-y: auto !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  min-height: auto !important;
  margin-top: 10px !important;
  margin-bottom: 100px !important;
  -webkit-transition-duration: 0.28s !important;
  transition-duration: 0.28s !important;
}

.section-organisation-list {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.table-paging {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
}

.material-table-input {
  width: 30%;
}

.post-org-item-wrap {
  margin-top: 40px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.post-org-item-wrap-second {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.post-org-item-wrap-second li {
  line-height: 50px;
}

.post-org-item-wrap-second input[type="checkbox"] {
  margin: 0 10px;
}

.post-org-item-wrap-second input {
  border: none;
  border-bottom: 1px solid grey;
  outline: none;
}

.checkbox-left {
  margin: 0px 10px;
  width: 17px;
  height: 17px;
}

.in-d-sb input {
  position: relative;
  left: 12px;
}

.staff-list-org span {
  -webkit-box-flex: 4;
  -ms-flex: 4;
  flex: 4;
  width: 100%;
  margin: 0 10px;
}

.tree-staff-list .tree-child-one {
  height: auto;
  margin: 10px 0;
}

.tree-staff-list .tree-child-one .list-btn {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  top: 10px;
}

.tree-staff-list .tree-child-one .list-btn-btn {
  position: relative;
  top: 5px;
  margin: 0;
  outline: none;
  border: none;
  color: #006aff;
  cursor: pointer;
  background-color: transparent;
}

.tree-staff-list .tree-child-one .list-btn-btn:active,
.tree-staff-list .tree-child-one .list-btn-btn:focus,
.tree-staff-list .tree-child-one .list-btn-btn:hover {
  background-color: transparent !important;
}

.tree-staff-list .tree-child-one .list-btn-btn:active {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.tree-staff-list .tree-child-two div {
  margin: 10px;
}

.tree-staff-list .tree-child-two div input {
  margin-right: 5px;
}

.organization-checkbox {
  width: 17px;
  height: 17px;
}

.pin-code {
  margin-right: auto !important;
  margin-bottom: 10px;
}

.styles_react-code-input__CRulA input {
  font-size: 0.9rem;
  width: 50px !important;
  margin: 0 4px !important;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #888 !important;
}

.spinner-center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  text-align: center !important;
}

.create-position-input {
  width: 40% !important;
}

.popup-menu {
  width: 100% !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.WithStyles\(ForwardRef\(MenuItem\)\)-root-24:focus {
  background-color: var(--primary_color) !important;
}

.WithStyles\(ForwardRef\(MenuItem\)\)-root-60:focus {
  background-color: var(--primary_color) !important;
}

/* .MuiModal-root .MuiPaper-root.MuiPaper-elevation {
  margin-top: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24) !important;
} */

.MuiAutocomplete-popper {
  margin-top: 5px !important;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24) !important;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.WithStyles\(ForwardRef\(MenuItem\)\)-root-62.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  background-color: var(--primary_color) !important;
}

.products-create-organizations {
  width: 70% !important;
}

.autocomp-input {
  width: 200px !important;
}

.font-style-cell {
  text-align: left !important;
}

.products-create-organizations {
  width: 55px !important;
}

.x4_times {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 600px !important;
  margin-left: auto;
}

.x4_times div.w-100 {
  margin-right: 20px;
}

.d-flex-return {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
}

.modal-transfer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-paper {
  background-color: rgba(0, 0, 0, 0.15);
}

.modal-btn-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 50px;
}

#transition-modal-title {
  font-weight: 400;
}

.taskbar-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.taskbar-inside-projects-btn {
  font-size: 17px !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.org-button.text-align-right {
  margin-top: 10px;
}

.MuiFormControl-root.MuiTextField-root.w-100 {
  position: relative;
}

.MuiFormHelperText-root.Mui-error {
  position: absolute;
  bottom: -18px;
  font-family: "Nunito", sans-serif;
}

.switch-discount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.supplier-fields {
  width: 100% !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.supplier-fields .wrap-item {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 !important;
  flex: 1 !important;
}

.supplier-fields .wrap-item-one,
.wrap-item-two {
  padding-right: 10px;
}

.finance-cash-btn-child {
  width: 90%;
  height: 50px;
}

.finance-cash-cards-group.align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.MuiTreeItem-label {
  background-color: transparent !important;
}

.empty-text {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
  width: 100%;
  font-weight: lighter !important;
  text-align: center !important;
}

.MTableToolbar-highlight-8 {
  background-color: transparent !important;
  color: white !important;
}

.MuiDialog-paperWidthSm {
  width: 65% !important;
}

.MTableToolbar-highlight-61,
.MTableToolbar-highlight-10 {
  background-color: transparent !important;
  color: white !important;
}

.dialog-input {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  border: 1px solid #eaeaea !important;
  padding: 8px 7px !important;
  border-left: 0 !important;
  border-right: 0 !important;
  max-height: 50px !important;
}

.dialog-input-second {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  border: 1px solid #eaeaea !important;
  padding: 8px 7px !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.dialog-textField input[type="text"] {
  text-indent: 30px !important;
}

.create-barcode-wrapper {
  width: 100%;
  height: 100vh;
  background-color: white;
  overflow-y: scroll !important;
}

.create-barcode-wrapper-top {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding-left: 78px;
  height: 50px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24) !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24) !important;
}

.create-barcode-print-btn {
  padding: 30px 30px 0 78px;
}

.print-btn {
  width: 90px !important;
  height: 37px !important;
  border-radius: 2px !important;
  font-size: 16px !important;
}

.category-table-child {
  padding: 4px !important;
}

.create_barcode_card {
  width: 210mm;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 78px;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  padding: 30px 0;
  overflow-x: hidden !important;
  border-collapse: collapse;
}

.format_zebra {
  width: 58mm;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 78px;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.create_barcode_child_one {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  width: 70mm;
  height: 37mm;
}

.create_barcode_child_second {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  width: 70mm;
  height: 29.7mm;
}

.create_barcode_child_third {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  width: 52.5mm;
  height: 29.7mm;
}

.create_barcode_child_four {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  width: 40mm;
  height: 30mm;
}

.create_barcode_child_five {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  width: 58mm;
  height: 40mm;
}

.create_barcode_child_six {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  width: 30mm;
  height: 21mm;
}

.create_barcode_card tr {
  border: 1px solid dimgray;
}

.format_zebra tr {
  border: 1px solid dimgray;
}

.create_barcode_child_one span,
.create_barcode_child_second span,
.create_barcode_child_third span {
  padding: 5px 0 0 0;
  font-size: 12px;
}

.create_barcode_child_four span,
.create_barcode_child_five span,
.create_barcode_child_six span {
  font-size: 13px;
  text-align: center !important;
}

.create-barcode td {
  text-align: center !important;
}

.empty-data {
  text-align: center !important;
  width: 100% !important;
}

.barcode-table-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 96.5%;
}

@media only screen and (max-width: 576px) {
  .x4_times {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    width: 100% !important;
  }

  .header-one {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .create-barcode-card {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

@media only screen and (max-width: 440px) {
  .header
    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    width: 200px !important;
  }

  .header-one .btn-header,
  .header-two .btn-header {
    width: 200px !important;
  }
}

#spring-popper {
  margin-top: 10px !important;
  box-shadow: 0 5px 25px 0 #0000001a !important;
}

/* marings */

.me-2 {
  margin-right: 10px !important;
}
