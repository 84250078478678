.button-switch {
  font-size: 1.5em;
  height: 24px;
  margin-bottom: 0.625em;
  position: relative;
  width: 42px;
}

.button-switch .lbl-off,
.button-switch .lbl-on {
  cursor: pointer;
  display: block;
  font-size: 0.9em;
  font-weight: bold;
  line-height: 1em;
  position: absolute;
  top: 1.5px;
  transition: opacity 0.25s ease-out 0.1s;
  text-transform: uppercase;
  pointer-events: none;
}

.button-switch .lbl-off {
  right: 4px !important;
}

.button-switch .lbl-on {
  left: 4px;
}

.button-switch .lbl-on {
  opacity: 0;
}

.button-switch-checked .lbl-on {
  opacity: 1;
}

.button-switch-checked .lbl-off {
  opacity: 0;
}

.button-switch .switch {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 0;
  font-size: 1em;
  left: 0;
  line-height: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 0;
}

.button-switch .switch:before,
.button-switch .switch:after {
  content: "";
  font-size: 1em;
  position: absolute;
}

.button-switch .switch:before {
  border-radius: 1.25em;
  background: #e2e2e2;
  height: 24px;
  transition: background-color 0.25s ease-out 0.1s;
  width: 42px;
}

.button-switch .switch:after {
  border-radius: 50%;
  background: #fefefe;
  height: 14px;
  transform: translate(4.5px, 5px);
  transition: transform 0.25s ease-out 0.1s;
  width: 14px;
  box-shadow: none !important;
}

.button-switch-checked .switch:after {
  transform: translate(23px, 5px) !important;
}

.button-switch-checked input:before {
  background: #026aff !important;
}
