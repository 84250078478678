.bg-sky {
  background: #006aff !important;
}

.radius-12 {
  border-radius: 12px !important;
}

.custom-form-control div {
  border-radius: 12px !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.form-control input {
  padding: 0.5rem 1rem !important;
}

.form-control {
  border: 1px solid #b7bdc6;
  border-radius: 13px;
  overflow: hidden;
}

/* displays */
.d-flex {
  display: flex;
}

/* flex alignment */
.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  box-shadow: 0 0 3px 0px #b7bdc6 !important;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
  overflow-x: auto;
  max-height: 350px;
}
